import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './App.css';
import newlogo from "./images/newlogo.png";
import CloseIcon from '@mui/icons-material/Close';
import wait from "./images/wait.svg";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import site from "./images/site.png";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoginIcon from '@mui/icons-material/Login';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import {Helmet} from 'react-helmet';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CodeIcon from '@mui/icons-material/Code';


const Privacy = () => {

const user_info = JSON.parse(localStorage.getItem('user'));
const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

const changeColor = (color) => {
	console.log(color);
	if(color === '#1dd1a1'){
			setStyle('bodyColor1');
	}
	if(color === '#ff6b6b'){
			setStyle('bodyColor2');
	}
	if(color === '#2e86de'){
			setStyle('bodyColor3');
	}
	if(color === '#f368e0'){
			setStyle('bodyColor4');
	}
	if(color === '#ff9f43'){
			setStyle('bodyColor5');
	}
}

const [toggle, setToggle] = useState('switch to register');
const navigate = useNavigate();
const [style, setStyle] = useState("bodyColor1");
const [show, setShow] = useState(false);
const [user, setUser] = useState({
		email: "",
		password: "",
		cnf_password: ""
});

const [login, setLogin] = useState({
		submit: false,
		status : false,
		invalid: false,
		verification: false,
		account : '',
		userID : 0,
		user_email: '',
		user_exist : false,
		user_exist_text : 'Email already exists' 
});

const [forgotPass, setForgotPass] = useState(false);
const [resetPass, setResetPass] = useState(false);
const [forgot_pass_email, setForgotPassEmail] = useState('');
const [forgot_pass_submit, setForgotPassSubmit] = useState(false);
const [resetPassOTP, setResetPassOTP] = useState('');
const [resetPassNew, setResetPassNew] = useState('');
const [resetPassConfirm, setResetPassConfirm] = useState('');
const [reset_pass_submit, setResetPassSubmit] = useState(false);
const [resetPassSuccess, setResetPassSuccess] = useState(false);
const [resetPassFailure, setResetPassFailure] = useState(false);

 const toggleMenu = () => {
		let navigation = document.querySelector('.navigation');
			let toggle = document.querySelector('.toggle');
			navigation.classList.toggle('active');
 }

 const handleSubmit = (e) => {
		e.preventDefault();
		
		console.log(user);
		if(user.email === '' || user.password === ''){
			alert('Please enter email and password');
            return;
		}
		if(user.password !== user.cnf_password){
			alert('Password and confirm password not matches');
            return;
		}
		
		setLogin({...login, submit : true});

		fetch('https://oneclickhuman.com/oneclickprice/register', {
			mode:'cors', 
			method: 'POST',
			body: JSON.stringify({
				'email' : user.email,
				'password' : user.password
			}),
			headers: {
				 'Content-type': 'application/json; charset=UTF-8',
			}
		}).then(res => res.json())
			.then((json) => {
				 console.log(json);
				 if(json.id === 0){
                     setLogin({...login, user_exist : true}); 
                     setToggle('switch to login'); 
				 }else{
				    setLogin({...login, account : 'pending', userID : json.id, user_email: json.user_email});	
				 }
		});
 };

  const handleSubmitLogin = (e) => {
		e.preventDefault();
		
		console.log(user);
		if(user.email === '' && user.password === ''){
			alert('Please enter email and password');
            return;
		}
		setLogin({...login, user_exist : false}); 
		setLogin({...login, submit : true, invalid: false, verification: false});

		fetch('https://oneclickhuman.com/oneclickprice/login', {
			mode:'cors', 
			method: 'POST',
			body: JSON.stringify({
				'email' : user.email,
				'password' : user.password
			}),
			headers: {
				 'Content-type': 'application/json; charset=UTF-8',
			}
		}).then(res => res.json())
			.then((json) => {
				 console.log(json);
				 if(json.login === 'success'){
						 setLogin({...login, account : 'active', userID : json.id, user_email: json.user_email, invalid: false});
						 localStorage.setItem('user', JSON.stringify({
						  	account : 'active',
						  	userID: json.id,
						  	user_email: json.user_email
						  }));
						 setTimeout(() => {
							setShow(false);
						 }, 500);

				 }else if(json.login === 'on-verification'){
				 	setLogin({...login, verification : true});
				 }else{
           setLogin({...login, invalid : true});
				 }
		});
 };

const signOut = (e) => {
	e.preventDefault(); 
	setLogin({...login, submit: false, status : false, invalid: false, account: '', userID: 0});
	localStorage.removeItem('user');
	var toast = document.getElementById("toast-logout");
  var progress = document.getElementById("progress-logout");
  toast.classList.add("active");
  progress.classList.add("active");
  setTimeout(() =>{
       toast.classList.remove("active");
  }, 5000);
  setTimeout(() =>{
       progress.classList.remove("active");
  }, 5300);
}
const forgotPassword = () => {
   setForgotPass(true);
   setToggle("");
}
const sendOTP = (e) => {
   e.preventDefault();

   if(forgot_pass_email === ''){
       alert('Please enter your email');
       return;
   }
   setForgotPassSubmit(true);
   fetch('https://oneclickhuman.com/oneclickprice/forgot_password', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : forgot_pass_email
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
              //console.log(json); 
              if(json.status === 'success'){
                   setForgotPassSubmit(false);
                   setForgotPass(false);
                   setResetPass(true);
              }
    });
}
const handleResetPassword = (e) => {
   e.preventDefault();
   if(resetPassOTP === ''){
       alert('Please Enter OTP');
       return;
   }  
   if(resetPassNew === ''){
       alert('Please Enter new password');
       return;
   }
   if(resetPassConfirm === ''){
       alert('Please Enter confirm password');
       return;
   }
   if(resetPassNew !== resetPassConfirm){
       alert('New Password and Confirm Password does not match!');
       return;
   }

   setResetPassSubmit(true);
   setResetPassFailure(false);

      fetch('https://oneclickhuman.com/oneclickprice/reset_password', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : forgot_pass_email,
        'new_password' : resetPassNew,
        'otp' : resetPassOTP
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
              //console.log(json); 
              if(json.status === 'success'){
                   setResetPassSuccess(true);
                   setResetPassSubmit(false);
              }else{
                 setResetPassSubmit(false);
                 setResetPassFailure(true);
              }
    });  
}
const userClick = (e) => {
	e.preventDefault();
   if(login.account !== 'active'){
		  setShow(true);
	}else{
    navigate("/account");
  }
}
const userClickMobile = (e) => {
    e.preventDefault();
    if(login.account !== 'active'){
        setShow(true);
        var element = document.getElementById("offcanvasNavbar");
        element.classList.remove("show");
        
        if(document.getElementsByClassName("offcanvas-backdrop")){
            var backdrope = document.getElementsByClassName("offcanvas-backdrop");
            backdrope[0].remove();
        }
    }
}
const resendEmailVerification = () => {
   //console.log(login.userID);
   fetch('https://oneclickhuman.com/oneclickprice/resend_email_verification', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : login.user_email,
        'user_id' : login.userID,
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
         //console.log(json);
         if(json.status === 'success'){
              alert('Verification Email is resent.');
         }
      });
}

useEffect(() => {
   if (user_info) {
         setLogin({...login, account: user_info.account, userID: user_info.userID, user_email: user_info.user_email});
   }
}, [1]);


 return(
       <>

         <Helmet>
           <title>Privacy Policy - OneClickHuman</title>
           <meta name="description" content="" />
         </Helmet>


<div className="wrapper">
        <div className="header-wrapper">
            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand gap-3">
                        <div className="topbar-logo-header d-none d-lg-flex">
                            <div className="">
                                <h4 className="logo-text">
                                  <a href="/">
                                    <img src={newlogo} className="newlogo"/>  
                                  </a>
                                </h4>
                            </div>
                        </div>
                        <div className="mobile-toggle-menu d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"><i className='bx bx-menu'></i></div>
         
         { matches === true ? 
               <div className="primary-menu">
                   <nav className="navbar navbar-expand-lg align-items-center">
                      <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header border-bottom">
                            <div className="d-flex align-items-center">
                                <div className="">
                                   { /*** <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon"> ****/}
                                </div>
                                <div className="">
                                    <h4 className="logo-text">
                                      <a href="/">
                                        <img src={newlogo} className="newlogo"/>  
                                      </a>
                                    </h4>
                                </div>
                            </div>
                          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav align-items-center flex-grow-1">
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/">
                                    <div className="parent-icon"><i className='bx bx-home-alt'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Home</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/pricing">
                                    <div className="parent-icon"><i className='bx bx-dollar'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Pricing</div>
                                </a>                                
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/help">
                                    <div className="parent-icon"><i className='bx bx-question-mark'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Help</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/blog">
                                    <div className="parent-icon"><i className='bx bxs-note'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Blog</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/developer/api" >
                                    <div className="parent-icon"><i className='bx bx-code'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">API</div>
                                </a>
                              </li>
                          </ul>
                        </div>
                      </div>
                  </nav>
            </div>
              :
              ''
             }

               { login.account === 'active' ?
                       <>
                       { matches === false ? 
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="/">
                              <img src={newlogo} className="newlogo"/>
                            </a>
                        </div>
                         :
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="user-info">
                                   <p className="user-name mb-0">{login.user_email}</p>  
                                </div>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={userClick}><i class="bx bx-user fs-5"></i><span>Account</span></a>
                                </li>
                                <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={signOut}><i class="bx bx-log-out-circle"></i><span>Logout</span></a>
                                </li>
                            </ul>
                        </div>
                       }
                        </>
                        :
                        <>
                        { matches === false ? 
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="/">
                              <img src={newlogo} className="newlogo"/>
                            </a>
                        </div>
                        : 
                        <div className="user-box px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="#" role="button">
                                <div className="user-info">
                                 <button onClick={() => { setShow(true); setToggle('switch to login');}}><LoginIcon/> Login</button>
                                </div>
                            </a>
                        </div>
                        }
                        </>
                    }

                    </nav>
                </div>
            </header>

 { matches === false ? 
                <div className="primary-menu">
                   <nav className="navbar navbar-expand-lg align-items-center">
                      <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header border-bottom">
                            <div className="d-flex align-items-center">
                                <div className="">
                                   { /*** <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon"> ****/}
                                </div>
                                <div className="">
                                    <h4 className="logo-text">
                                      <a className="d-flex align-items-center nav-link gap-3" href="/">
                                        <img src={newlogo} className="newlogo"/>
                                      </a>
                                    </h4>
                                </div>
                            </div>
                          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav align-items-center flex-grow-1">
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/">
                                    <div className="parent-icon"><i className='bx bx-home-alt'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Home</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/pricing">
                                    <div className="parent-icon"><i className='bx bx-dollar'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Pricing</div>
                                </a>                                
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/help">
                                    <div className="parent-icon"><i className='bx bx-question-mark'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Help</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/blog">
                                    <div className="parent-icon"><i className='bx bxs-note'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Blog</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/developer/api">
                                    <div className="parent-icon"><i className='bx bx-code'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">API</div>
                                </a>
                              </li>
                             <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="#" onClick={userClickMobile}>
                                    <div className="parent-icon"><i className='bx bx-user'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">{ login.account === 'active' ? login.user_email : 'Sign in'}</div>
                                </a>
                              </li>
                          </ul>
                        </div>
                      </div>
                  </nav>
            </div>
              :
              ''
             }

           </div>


            <div className="page-wrapper">
            <div className="page-content">
                <div className="row">
                    <div className="col-xl-10 mx-auto">
                        <div className="card border-top border-0 border-4 border-white">
                            <div className="card-body p-0">


	 <AccountBox id={ matches === true ? "": "tool-mobile" }>
        <Heading>
          <TuneIcon/> <b>Privacy Policy</b>	
        </Heading>

        <Content>
          <div className="innerAcc">
            <div>
The privacy of our visitors to https://oneclickhuman.com is important to us.<br></br><br></br>

At editwishes.com, we recognize that privacy of your personal information is important. Here is information on what types of personal information we receive and collect when you use and visit editwishes.com, and how we safeguard your information. We never sell your personal information to third parties.<br></br><br></br>

<h3>Log Files</h3>
As with most other websites, we collect and use the data contained in log files. The information in the log files include your IP (internet protocol) address, your ISP (internet service provider, such as AOL or Shaw Cable), the browser you used to visit our site (such as Internet Explorer or Firefox), the time you visited our site and which pages you visited throughout our site.<br></br><br></br>

<h3>Cookies and Web Beacons</h3>
We do use cookies to store information, such as your personal preferences when you visit our site. This could include only showing you a popup once in your visit, or the ability to login to some of our features, such as forums.<br></br>
<br></br>
We also use third party advertisements on editwishes.com to support our site. Some of these advertisers may use technology such as cookies and web beacons when they advertise on our site, which will also send these advertisers (such as Google through the Google AdSense program) information including your IP address, your ISP , the browser you used to visit our site, and in some cases, whether you have Flash installed. This is generally used for geotargeting purposes (showing New York real estate ads to someone in New York, for example) or showing certain ads based on specific sites visited (such as showing cooking ads to someone who frequents cooking sites).<br></br><br></br>

<h3>DoubleClick DART cookies</h3>
We also may use DART cookies for ad serving through Google’s DoubleClick, which places a cookie on your computer when you are browsing the web and visit a site using DoubleClick advertising (including some Google AdSense advertisements). This cookie is used to serve ads specific to you and your interests (”interest based targeting”). <br></br>
<br></br>
The ads served will be targeted based on your previous browsing history (For example, if you have been viewing sites about visiting Las Vegas, you may see Las Vegas hotel advertisements when viewing a non-related site, such as on a site about hockey). DART uses “non personally identifiable information”. It does NOT track personal information about you, such as your name, email address, physical address, telephone number, social security numbers, bank account numbers or credit card numbers.<br></br>
<br></br>
You can opt-out of this ad serving on all sites using this advertising by visiting http://www.doubleclick.com/privacy/dart_adserving.aspx
<br></br><br></br>
You can choose to disable or selectively turn off our cookies or third-party cookies in your browser settings, or by managing preferences in programs such as Norton Internet Security. However, this can affect how you are able to interact with our site as well as other websites. This could include the inability to login to services or programs, such as logging into forums or accounts.
<br></br><br></br>
Deleting cookies does not mean you are permanently opted out of any advertising program. Unless you have settings that disallow cookies, the next time you visit a site running the advertisements, a new cookie will be added.
<br></br>
</div>
          </div>
        </Content>

	 </AccountBox>

	                       </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>


        <div className="overlay toggle-icon"></div>
        <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
        <footer className="page-footer">
            <p className="mb-0">Copyright ©. All right reserved.</p>
        </footer>
   

        <div className="switcher-wrapper">
        <div className="switcher-btn"> <i className='bx bx-cog bx-spin'></i>
        </div>
        <div className="switcher-body">
            <div className="d-flex align-items-center">
                <h5 className="mb-0 text-uppercase" style={{fontSize: '1rem'}}>Change Background</h5>
                <button type="button" className="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr/>
            <p className="mb-0">Gaussian Texture</p>
              <hr/>
              
              <ul className="switcher">
                <li id="theme1"></li>
                <li id="theme2"></li>
                <li id="theme3"></li>
                <li id="theme4"></li>
                <li id="theme5"></li>
                <li id="theme6"></li>
              </ul>
               <hr/>
              <p className="mb-0">Gradient Background</p>
              <hr/>
              
              <ul className="switcher">
                <li id="theme7"></li>
                <li id="theme8"></li>
                <li id="theme9"></li>
                <li id="theme10"></li>
                <li id="theme11"></li>
                <li id="theme12"></li>
                <li id="theme13"></li>
                <li id="theme14"></li>
                <li id="theme15"></li>
              </ul>
        </div>
    </div>


	{ show === true ?
		 <div id="popup">
				<div id="login-form">
					{ toggle === 'switch to register' ?
						<form onSubmit={handleSubmit}>
						   <h3 style={{marginBottom: '7px'}}>Create New Account!</h3>
						   <p className="already">Already have an account? <span onClick={() => setToggle('switch to login')}>Login in</span></p>
                  <span className="closeLogin" onClick={() => {setLogin({...login, submit: false, status : false, invalid: false, verification: false, account : '', userID : 0, user_email: '', user_exist : false, user_exist_text : 'Email already exists'}); setShow(false); }}><CloseIcon/></span> 
							<div className="inputBox">
								<input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
								<span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
							</div>
							
							<div className="inputBox">
							   <input type="password" placeholder="Password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
							</div>

							<div className="inputBox">
							   <input type="password" placeholder="Confirm password" onChange={(e) => setUser({ ...user, cnf_password: e.target.value })} />
							</div>

							<button type="submit">SIGN UP</button>
							{ login.submit === true ?
								<p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
								: ''
							}
							{ login.account === 'pending' ?
               <>
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />awaiting email verification....</p>
                 <p id="resend-verification" style={{color: '#fff'}}><span onClick={resendEmailVerification}>Resend email verification</span></p>
                </>
								: ''
							}
							{ login.account === 'active' ?
								<p id="verfied" style={{color: '#fff'}}>Account activated</p> 
								: ''
							}
							<div className="form-footer">
                <span className="left-foot">All fields are required.</span>
                <span className="right-foot" onClick={() => setToggle('switch to login')}><LockIcon/> Login</span> 
							</div>
						</form>
						: 
           ''}

               { toggle === 'switch to login' ?
             <form onSubmit={handleSubmitLogin}>
              <h3>Welcome Back!</h3>
                            <span className="closeLogin" onClick={() => setShow(false)}><CloseIcon/></span> 

              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
                <span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
              </div>
              
              <div className="inputBox">
                 <input type="password" placeholder="Enter your password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
              </div>
              <div id="forgot-pass">
                <span onClick={forgotPassword}>Forgot Password</span> 
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>LOGIN</button>
              { login.submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                : ''
              }
              { login.user_exist === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>{login.user_exist_text}</p> 
                : ''
              }
              { login.account === 'active' ?
                <p id="verfied" style={{color: '#fff'}}>Login Success</p> 
                : ''
              }
              { login.invalid === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Invalid username or password</p> 
                : ''
              }
              { login.verification === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Email verification is pending</p> 
                : ''
              }
              <div className="form-footer">
                              <span className="left-foot">All fields are required.</span>
                              <span className="right-foot" onClick={() => setToggle('switch to register')}><AccountCircleIcon/> Sign Up</span> 
              </div>
            </form>
            : ''
          }
          { forgotPass === true ?
            <form onSubmit={sendOTP}>
              <h3>Forgot Password</h3>
              <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>Submit your email id, we will send an otp for resetting new password.</p>
              <span className="closeLogin" onClick={() => {setShow(false);setForgotPass(false)}}><CloseIcon/></span>
              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setForgotPassEmail(e.target.value)} />
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>SEND OTP</button>
              { forgot_pass_submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Sending OTP....</p> 
                : '' 
              }
              <div className="form-footer">
                <span className="left-foot">All fields are required.</span>
                <span className="right-foot" onClick={() => {setToggle('switch to register');setForgotPass(false)}}><AccountCircleIcon/> Sign Up</span> 
              </div>
            </form>
                       : ''
          }

          { resetPass === true ?
                       <form onSubmit={handleResetPassword}>
              <h3>Reset Password</h3>
              <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>We have sent an OTP to your email id, Please enter otp and set new password</p>
                            <span className="closeLogin" onClick={() => {setShow(false);setResetPass(false)}}><CloseIcon/></span>
              <div className="inputBox">
                 <input type="text" placeholder="Enter OTP" onChange={(e) => setResetPassOTP(e.target.value)} />
              </div>
              <div className="inputBox">
                 <input type="password" placeholder="Enter New Password" onChange={(e) => setResetPassNew(e.target.value)} />
              </div>
              <div className="inputBox">
                 <input type="text" placeholder="Confirm New Password" onChange={(e) => setResetPassConfirm(e.target.value)} />
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>SUBMIT</button>
              { reset_pass_submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                : '' 
              }
              { resetPassSuccess === true ?
                <p style={{color: '#5bed61', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>New Password set Successfully, <span style={{cursor: 'pointer', }} onClick={() => {setResetPass(false);setToggle('switch to login'); }}>Login</span></p>  
                : ''  
              }
              { resetPassFailure === true ?
                <p style={{color: 'red', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>Invalid OTP, try again</p>    
                : ''
              }
            </form> 
            : ''
          }
				</div>
		 </div>
			: ''
}


</div>

{ matches === true ?
  <div className="toast" id="toast-logout">
    <div className="toast-content">
      <div className="message">
        <span className="message-text text-2"><DoneIcon/> Logged Out</span>
      </div>
    </div>
    <div className="progress" id="progress-logout"></div>
  </div>
    : ''
}
 
       </>
  	)
}

export default Privacy;

const Progress = styled.div`
   width:100%;
   margin-bottom: 20px;

   .progress-top {
      display: flex;
      margin-bottom: 5px;

      p {
         width: 100%;
      }
      .progress-label {
         font-size: 14px;
      }
      .progress-value {
        text-align: right;
        font-size: 13px;
      }
    }

    .progress-btm {
      font-size: 12px;
      text-align: right;
      margin-top: 4px;
      color: #787878;
     }
`;

const SmallBtn = styled.div`
   display: flex;
   width:100%;

   button {
   	 position: static !important;
   	 margin-right: 10px;
     width: unset !important;
     height: unset !important;
     padding: 8px 12px;
     font-size: 13px; 
   }
`;

const Row = styled.div`
   width: 100%;
   display: flex;
   margin-bottom: 30px;

   .column-inner {
      width: 100%;
      padding: 0 15px;

      .note {
      	 font-size: 12px;
         background: #e7f4fd;
         color: #0f08ad;
         font-weight: 500;
         line-height: 20px;
         padding: 10px;
      }
   }
`;

const Card = styled.div`
   width:100%;
   padding: 20px;
   border-radius: 15px;
   background: #fff;
   box-shadow: 1px 1px 13px 1px #b8b5b5;
   position: relative;
   margin-bottom: 40px;

   textarea {
     min-height: 100px;
    height: 100px;
    border: 1px solid #eee;
    border-radius: 3px;
    margin-bottom:0;
    padding: 12px;
    font-size: 14px;
   }

   .features {
    font-size: 14px;
    line-height: 27px;
    color: #000;
   }

   .plan {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 25px;

    span {
      border-radius: 8px;
      background: #000;
      color: #fff;
      padding: 5px 12px;
      margin-right: 5px;
    }
   }

   .plan-amnt {
      position: absolute;
      top: 20px;
      right: 20px;
      line-height: 44px;

      span:nth-child(1) {
        font-size: 20px;
        vertical-align: top;
      }

      span:nth-child(2) {
        font-size: 50px;
        font-weight: 600;
        vertical-align: text-top;
      }

      span:nth-child(3) {
        font-size: 14px;
        color: #777;
        vertical-align: top;
      }
    }

   button {
    background: #2196f3;
    color: #fff;
    border: 0;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 145px;
    height: 40px;
    cursor: pointer;

     svg { vertical-align: middle; }
   }
`;

const Heading = styled.div`
   width:100%;
   padding: 20px 15px;
   background: rgb(0 0 0 / 12%);
   color: #fff;
   font-size: 16px;

   svg { vertical-align: bottom; }
`;

const AccountBox = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
    height: 545px;
`;

const Content = styled.div`
   width:100%;
   overflow-x: hidden;

   .innerAcc {
   	  width: 103%;
   	  overflow-y: scroll;
   	  height: 450px;
   	   padding: 30px;
   }
`;

