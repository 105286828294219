import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Account from './Account';
import Help from './Help';
import Privacy from './Privacy';
import Tos from './Tos';
import Ltd from './Ltd';
import Documentation from './Documentation';
import Affiliate from './Affiliate';
import Payouts from './Payouts';
import AffiliateTerms from './AffiliateTerms';
import Pricing from './Pricing';
import Api from './Api';
import Admin from './Admin';
import Users from './Users';
import Payment from './Payment';
import Settings from './Settings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <Router>
     <Routes>
        <Route path="/" element={<App />} />
        <Route path="/emailVerified" element={<App verify="true" />} />
        <Route path="/account" element={<Account/>} />
        <Route path="/help" element={<Help/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/tos" element={<Tos/>} />
        <Route path="/ltd" element={<Ltd/>} />
        <Route path="/documentation" element={<Documentation/>} />
        <Route path="/affiliate" element={<Affiliate/>} />
        <Route path="/payouts" element={<Payouts/>} />
        <Route path="/affiliate-terms" element={<AffiliateTerms/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route path="/developer/api" element={<Api/>} />
        <Route path="/admin" element={<Admin/>} />
        <Route path="/admin/users" element={<Users/>} />
        <Route path="/admin/payments" element={<Payment/>} />
        <Route path="/admin/settings" element={<Settings/>} />
     </Routes>
   </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
